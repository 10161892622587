import React from 'react'
import { Banner } from "../components/Banner";
import { Skills } from "../components/Skills";
import { Projects } from "../components/Projects";
import { Contact } from "../components/Contact";
import { motion } from "framer-motion";

export const Home = () => {
  return (
    <motion.div 
      className="Home"
      initial={{width: 0}}
      animate={{width: "100%"}}
      exit={{x: window.innerWidth, transition: { duration: 0.3}}}
    >
        
        <Banner />
        <Skills />
        <Projects />
        <Contact />
    </motion.div>
  )
}
