import { Container, Row, Col } from "react-bootstrap";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const BannerAbout = () => {
  return (
    <section className="banner2" id="home">
      <Container>
        <Row className="aligh-items-center">
        <h2 className="mb-4">About Us</h2>
          <Col xs={12} md={6} xl={6}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                
                <p className="bg-dark bg-opacity-50">PT. InterCoastal Indonesia was established in February 2001 by deed of public notary Yonsah Minanda SH, MH under articles of corporation number 27 dated Ferbruary 07,
                           2001 and licensed by Industrial and Trade Department of The Republic of Indonesia No. 294/09-04/PM/2001. 
                           PT. InterCoastal Indonesia was appointed exclusively as marketing by RaetsMarine Insurance B.V main office which located at Fascina Boulevard 622, 2909 VA Capelle a/d Ijssel, Netherlands.
                            To provide fixed premium facility for vessels under 5000 GT with the limit of liabilities of USD 5,000,000.</p>
                <p className="bg-dark bg-opacity-50">Based on the deed of public notary Yonsah Minanda SH, MH articles number 10 dated 09 October 2003 and the Minister of Finance of the Republic Indonesia’s decree number
                           kep 131/KM.5/2005 dated April 27, 2005 the status of PT. InterCoastal Indonesia changed to become an insurance brokers and risks consultant company. 
                           In compliance to regulations that require each insurance broker company to be registered to relevant official association, 
                           PT InterCoastal Indonesia has become a member of APPARINDO (Insurance and Reinsurance Broker Association of Indonesia) since August 5th 2005 under registry number 116-2005/APPARINDO/2013</p>
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={6}>
          <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <p className="bg-dark bg-opacity-50">In 2012, RaetsMarine Insurance B.V has been bought off by MS Amlin Insurance Group which is located in Netherlands. Due to heavy alteration within the management by MS Amlin Insurance Group towards RaetsMarine Insurance B.V, PT. InterCoastal Indonesia has decided to extend their partnership to other international fixed premium facilities & group.</p>
                <p className="bg-dark bg-opacity-50">As per the year 2016, PT. Cipta Uni Jasa Insurance Brokers who has thirty (30) years experience in the Insurance Industry, especialy in Maritime Insurance, merged with PT. InterCoastal Indonesia Insurance Brokers. Most of our clients are ranging from private shipping company until government sector including non-marine insurance</p>
                <p className="bg-dark bg-opacity-50">PT InterCoastal Indonesia is the premier P&I and Marine Insurance Broker in Indonesia, working closely with world-class P&I providers, both fixed premium facilities and mutual clubs, along with Hull Underwriters, local and international, to provide the best service for our clients. For Marine Insurance facultative back-up security, our sister company PT Cipta Colemon Asia are always ready to provide assitance to make Marine Hull and Cargo placement as well as smooth and effortless claims handling.</p>      
              </div>}
            </TrackVisibility>  
          </Col>
          <div className="animate__animated animate__fadeIn">
            <span className="navbar-text">    
              <a href="https://wa.me/6282124940209" target="_blank" rel="noopener noreferrer"><button className="vvd"><span>Chat Us!</span> <ArrowRightCircle size={25} /></button></a>
            </span>  
          </div>
        </Row>
      </Container>
    </section>
  )
}
