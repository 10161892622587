import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/owner_intercoastal.jpg";
import projImg2 from "../assets/img/hull_intercoastal.png";
import projImg3 from "../assets/img/cargo_intercoastal.jpg";
import projImg4 from "../assets/img/contractor_intercoastal.jpg";
import projImg5 from "../assets/img/oil_intercoastal.jpg";
import projImg6 from "../assets/img/travel_intercoastal.jpg";
import projImg7 from "../assets/img/surety.jpg";
import projImg8 from "../assets/img/money_insurance.jpg";
import projImg9 from "../assets/img/corporate_liability.jpg";
import colorSharp2 from "../assets/img/color-sharp2.png"
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Owners P&I",
      description: "We offer comprehensive P&I packages that can be tailor made to suit the needs of every ship-owner, no matter how small. Fixed Facilities such as Carina, QBE, Eagle Ocean Marine, RaetsMarine and so on, provide P&I coverage for smaller Tonnages (GT) and does not cater to Trans-Atlantic, Trans-Pacific voyages, as well as trading to and from US; while Mutual Clubs from the International Group of P&I Associations, such as Britannia, UK Club, Skuld, Gard, etc, will accept bigger tonnages but avoid singletons or small fleets.",
      imgUrl: projImg1,
    },
    {
      title: "Marine Hull (M&H)",
      description: "PT InterCoastal Indonesia can find you a solution for underwriting hull policies successfully. All knowledge on claims handling, condition surveys and loss prevention may contribute to creating a competitive edge to the benefit of both the ship-owner and the insurer. Only long term loyalty and commitment can create viable and sustainable solutions. We are aiming at the total relationship with the ship-owner and will therefore expect that the P&I policies to also be placed through us.",
      imgUrl: projImg2,
    },
    {
      title: "Marine Cargo",
      description: "Principally we can offer a solution to marine cargo insurance for any type of cargo. We understand the charterers' and ship-owners' business, which means we can give valuable advises about loss prevention and legal advises about charter parties and bills of lading. Also in the handling of claims we believe that and protect the client's position in claims which are recoverable from parties who have caused the damage, which will benefit the client's loss record and create a competitive edge on the pricing levels of the cargo insurance.",
      imgUrl: projImg3,
    },
  ];

  const projects2 = [
    {
      title: "Contractor All Risk",
      description: "This policy offers comprehensive protection against loss and/or damage in respects of civil engineering construction works and mechanical electrical works",
      imgUrl: projImg4,
    },
    {
      title: "Energy, Oil & Gas",
      description: "This policy covers risks of the energy plant either during operation or silent risks; construction of drilling rigs, platform in operation, off-shore builder's risks, floating risks, control of well and other types related to oil & gas industry.",
      imgUrl: projImg5,
    },
    {
      title: "Travel Insurance",
      description: "This policy covers the costs and losses associated with traveling. It is useful protection for those traveling domestically or abroad. The main categories of travel insurance include trip cancellation or interruption coverage, baggage and personal effects coverage, medical expense coverage, and accidental death or flight accident coverage. ",
      imgUrl: projImg6,
    }
  ];

  const projects3 = [
    {
      title: "Bank Guarantee & Surety Bond",
      description: "	A surety bond is a legally binding contract entered into by three parties—the principal, the obligee, and the surety. The obligee, usually a government entity, requires the principal, typically a business owner or contractor, to obtain a surety bond as a guarantee against future work performance. 	Provide guarantees in the form of counter guarantees for bank guarantee facilities issued by the Bank to the Principal if the Principal experiences a default in the performance of his work.",
      imgUrl: projImg7,
    },
    {
      title: "Money Insurance",
      description: "This policy protects your business money (which will include not only cash and cheques, but also items such as lottery tickets, gift cards, postal orders and stamps) by providing cover for loss or damage to it whilst on your premises or in transit.",
      imgUrl: projImg8,
    },
    {
      title: "Corporate Liability",
      description: "This policy offers protection against such events. It provides financial security – covering any funds needed to defend yourself and your company in a court of law and ensuring that, in such difficult times, your business stays afloat",
      imgUrl: projImg9,
    }
  ];

  return (
    <section className="project" id="services">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Some of our Services</h2>
                <p> We provide services for Marine Insurance, General Insurance and Financial Lines Insurance.
                </p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Marine Insurance</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">General Insurance</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Financial Lines</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Row>
                        {
                          projects2.map((project2, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project2}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <Row>
                        {
                          projects3.map((project3, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project3}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
          <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn d-flex justify-content-center": "d-flex justify-content-center"}>
                <span className="navbar-text">   
                  <a href="https://wa.me/6282124940209" target="_blank" rel="noopener noreferrer"><button className="vvd" style={{backgroundColor: "#333893"}}><span>Further Inquiry ?</span></button></a>    
                </span>  
              </div> } 
          </TrackVisibility>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="colorsharp"></img>
    </section>
  )
}
