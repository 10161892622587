import React from 'react'
import { BannerAbout } from '../components/BannerAbout';
import { Timeline } from '../components/Timeline';
import { Visimisi } from '../components/Visimisi';
import { motion } from 'framer-motion';


export const About = () => {
  return (
    <motion.div 
      className="About"
      initial={{width: 0}}
      animate={{width: "100%"}}
      exit={{x: window.innerWidth, transition: { duration: 0.3}}}
      >
        <BannerAbout />
        <Visimisi />
        <Timeline />
    </motion.div>
  )
}
