import React from 'react'
import { BannerService } from '../components/BannerService'
import { ServiceAll } from '../components/ServiceAll'
import { motion } from 'framer-motion';

export const Service = () => {
  return (
    <motion.div 
      className="Services"
      initial={{width: 0}}
      animate={{width: "100%"}}
      exit={{x: window.innerWidth, transition: { duration: 0.3}}}
    >
        <BannerService />
        <ServiceAll />
    </motion.div>
  )
}
