import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="about">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Briefly About Us</h2>
                        <p>PT. InterCoastal Indonesia was established in February 2001 by deed of public notary Yonsah Minanda SH, MH under articles of corporation number 27 dated Ferbruary 07,
                           2001 and licensed by Industrial and Trade Department of The Republic of Indonesia No. 294/09-04/PM/2001. 
                           PT. InterCoastal Indonesia was appointed exclusively as marketing by RaetsMarine Insurance B.V main office which located at Fascina Boulevard 622, 2909 VA Capelle a/d Ijssel, Netherlands.
                            To provide fixed premium facility for vessels under 5000 GT with the limit of liabilities of USD 5,000,000.</p>
                        <p>Based on the deed of public notary Yonsah Minanda SH, MH articles number 10 dated 09 October 2003 and the Minister of Finance of the Republic Indonesia’s decree number
                           kep 131/KM.5/2005 dated April 27, 2005 the status of PT. InterCoastal Indonesia changed to become an insurance brokers and risks consultant company. 
                           In compliance to regulations that require each insurance broker company to be registered to relevant official association, 
                           PT InterCoastal Indonesia has become a member of APPARINDO (Insurance and Reinsurance Broker Association of Indonesia) since August 5th 2005 under registry number 116-2005/APPARINDO/2013</p>
                    </div>
                    <div className="skill-bx-core wow zoomIn">
                      <h2 className="mb-3">Our Core Value</h2>   
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <FontAwesomeIcon className="py-3" icon="wifi-strong" size="5x" fade/>
                                <h5>Communication</h5>
                            </div>
                            <div className="item">
                                <FontAwesomeIcon className="py-3" icon="lightbulb" size="5x" fade/>
                                <h5>Solution</h5>
                            </div>
                            <div className="item">
                                <FontAwesomeIcon className="py-3" icon="hands-helping" size="5x" fade/>
                                <h5>Commitment</h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
