import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/logo.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import apparindo from "../assets/img/apparindo.png";
import mba from "../assets/img/mba.png";

export const Footer = () => {
  return (
    <footer className="footer my-3">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
            <img className="mx-2" src={logo} alt="Logo" />
            <img className="mx-2" src={apparindo} alt="Apparindo Logo" />
            <img className="mx-2" src={mba} alt="MBA Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://linkedin.com/"><img src={navIcon1} alt="Icon" /></a>
            </div>
            <p>Copyright 2022. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
