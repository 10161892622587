import React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom';
import { Home } from "../pages/Home";
import { About } from "../pages/About";
import { Service } from '../pages/Service';
import { AnimatePresence } from 'framer-motion';

const AnimatedRoutes = () => {
    const location = useLocation();
    return (
    <AnimatePresence>    
    <Routes location={location} key={location.pathname}>
         <Route exact path="/" element={<Home />} />
         <Route exact path="/about" element={<About />} />
         <Route exact path="/services" element={<Service />} />
    </Routes>
    </AnimatePresence>
  )
}

export default AnimatedRoutes