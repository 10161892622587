import { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Contact = () => {
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Send');
  const [status, setStatus] = useState({});
  const [error, setError] = useState({});

  const findFormErrors = () => {
    const { firstName, lastName, email, phone } = formDetails
    const newErrors = {}
    // firstname errors
    if ( !firstName || firstName === '' ) newErrors.firstName = 'Firstname cannot be blank!'
    else if ( firstName.length > 30 ) newErrors.firstName = 'Firstname is too long!'
    // lastname errors
    if ( !lastName || lastName === '' ) newErrors.lastName = 'Lastname cannot be blank!'
    else if ( lastName.length > 30 ) newErrors.lastName = 'Lastname is too long!'
    if ( !email || email === '' ) newErrors.email = 'Email cannot be blank!'
    if ( !phone || phone === '' ) newErrors.phone = 'Phone cannot be blank!'
    else if ( phone.length > 10 ) newErrors.phone = 'Phone number digit is too long!'

    return newErrors
}

  const onFormUpdate = (category, value) => {
      setFormDetails({
        ...formDetails,
        [category]: value
      })

      if ( !!error[category] ) setError({
        ...error,
        [category]: null
      })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = findFormErrors()

    if ( Object.keys(newErrors).length > 0 ) {
      // We got errors!
      setError(newErrors)
    } else {
      // No errors! Put any logic here for the form submission!
      setButtonText("Sending...");
      let response = await fetch("https://intercoastal.co.id/nodeemail/send_email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(formDetails),
      });
      setButtonText("Send");
      let result = await response.json();
      setFormDetails(formInitialDetails);
      if (result.code === 200) {
        setStatus({ success: true, message: 'Message sent successfully'});
      } else {
        setStatus({ success: false, message: 'Something went wrong, please try again later.'});
      }
    }
    
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2>Have Questions ? Ask Away.... </h2>
                <Row className="px-2 pb-4">
                    <Col size={12} sm={12} className="px-1">
                      <h4>
                      Graha Indhika Building <br/>
                      Jalan Usaha No. 22, Cawang, Kramatjati<br />
                      Jakarta Timur 13630
                      </h4>
                      <h5>
                      Phone : +6221 8370 5516 <br />
                      Email : inquiry@intercoastal.co.id <br />
                      </h5>
                    </Col>
                </Row>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                    <Form.Group>
                        <Form.Control 
                            type='text' 
                            placeholder="Firstname"
                            value={formDetails.firstName}
                            onChange={ (e) => onFormUpdate('firstName', e.target.value) }
                            isInvalid={ !!error.firstName }
                        />
                        <Form.Control.Feedback type='invalid'>
                          { error.firstName }
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                    <Form.Group>
                        <Form.Control 
                            type='text' 
                            placeholder="Lastname"
                            value={formDetails.lastName}
                            onChange={ (e) => onFormUpdate('lastName', e.target.value) }
                            isInvalid={ !!error.lastName }
                        />
                        <Form.Control.Feedback type='invalid'>
                          { error.lastName }
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                    <Form.Group>
                        <Form.Control 
                            type='text' 
                            placeholder="Email"
                            value={formDetails.email}
                            onChange={ (e) => onFormUpdate('email', e.target.value) }
                            isInvalid={ !!error.email }
                        />
                        <Form.Control.Feedback type='invalid'>
                          { error.email }
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                    <Form.Group>
                        <Form.Control 
                            type='text' 
                            placeholder="Phone Number"
                            value={formDetails.phone}
                            onChange={ (e) => onFormUpdate('phone', e.target.value) }
                            isInvalid={ !!error.phone }
                        />
                        <Form.Control.Feedback type='invalid'>
                          { error.phone }
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                    <Col size={12} className="px-1">
                      <textarea rows="6" value={formDetails.message} placeholder="Message" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                      <button type="submit"><span>{buttonText}</span></button>
                    </Col>
                    {
                      status.message &&
                      <Col>
                        <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                      </Col>
                    }
                  </Row>
                </form>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
