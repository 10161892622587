import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ServiceCard } from "./ServiceCard";
import projImg1 from "../assets/img/owner_intercoastal.jpg";
import projImg2 from "../assets/img/hull_intercoastal.png";
import projImg3 from "../assets/img/cargo_intercoastal.jpg";
import projImg4 from "../assets/img/contractor_intercoastal.jpg";
import projImg5 from "../assets/img/oil_intercoastal.jpg";
import projImg6 from "../assets/img/travel_intercoastal.jpg";
import projImg7 from "../assets/img/surety.jpg";
import projImg8 from "../assets/img/money_insurance.jpg";
import projImg9 from "../assets/img/corporate_liability.jpg";
import charterer from "../assets/img/charterer_intercoastal.jpg";
import port from "../assets/img/port_intercoastal.jpg";
import repair from "../assets/img/repair_intercoastal.jpg";
import builder from "../assets/img/builder_intercoastal.jpg";
import legal from "../assets/img/legal_intercoastal.jpg";
import additional from "../assets/img/additional_intercoastal.jpg";
import aviation from "../assets/img/aviation_intercoastal.jpg";
import airport from "../assets/img/airport_intercoastal.jpg";
import computer from "../assets/img/computer_intercoastal.jpg";
import erection from "../assets/img/erection_intercoastal.jpg";
import mv_personal from "../assets/img/mv_intercoastal.jpg";
import health from "../assets/img/personal_intercoastal.jpg";
import colorSharp2 from "../assets/img/color-sharp2.png"
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const ServiceAll = () => {

  const projects = [
    {
      title: "Owners P&I",
      description: "We offer comprehensive P&I packages that can be tailor made to suit the needs of every ship-owner, no matter how small. Fixed Facilities such as Carina, QBE, Eagle Ocean Marine, RaetsMarine and so on, provide P&I coverage for smaller Tonnages (GT) and does not cater to Trans-Atlantic, Trans-Pacific voyages, as well as trading to and from US; while Mutual Clubs from the International Group of P&I Associations, such as Britannia, UK Club, Skuld, Gard, etc, will accept bigger tonnages but avoid singletons or small fleets.",
      imgUrl: projImg1,
    },
    {
      title: "Marine Hull (M&H)",
      description: "PT InterCoastal Indonesia can find you a solution for underwriting hull policies successfully. All knowledge on claims handling, condition surveys and loss prevention may contribute to creating a competitive edge to the benefit of both the ship-owner and the insurer. Only long term loyalty and commitment can create viable and sustainable solutions. We are aiming at the total relationship with the ship-owner and will therefore expect that the P&I policies to also be placed through us.",
      imgUrl: projImg2,
    },
    {
      title: "Marine Cargo",
      description: "Principally we can offer a solution to marine cargo insurance for any type of cargo. We understand the charterers' and ship-owners' business, which means we can give valuable advises about loss prevention and legal advises about charter parties and bills of lading. Also in the handling of claims we believe that and protect the client's position in claims which are recoverable from parties who have caused the damage, which will benefit the client's loss record and create a competitive edge on the pricing levels of the cargo insurance.",
      imgUrl: projImg3,
    },
    {
        title: "Charterer P&I",
        description: "We welcome any type of charterers business, no matter how big or small. We can assist charterers to insure a chartered vessel of any type, cargo carrying or non-cargo carrying any type of commodity, any trading area worldwide at fixed premiums. The majority of charterers concerns tramp-chartering, both voyage and time charters, conducted by operators or by commodity traders who take to chartering vessels to carry their own cargoes. For those who ship their own cargoes, we can extend the cover to include Cargo Owners Legal Liability.",
        imgUrl: charterer,
    },
    {
        title: "Port Liability",
        description: "We will assist in designing a comprehensive and tailor-made liability cover for port authorities, terminal operators, cargo handling facilities, freight forwarders, etc. Of which the core is a comprehensive protection against third party liabilities, both contractual and non-contractual including an 'Errors and Omissions' cover. In addition we can offer 'one-stop' insurance programs to include coverage for any property or the Assured. Although this type of product is relatively new, we believe that we can play a significant role in this segment of the market. Through our claims professionals and our risk management department we can provide our clients with a substantial service level",
        imgUrl: port,
    },
    {
        title: "Ship Repairer Liability",
        description: "PT InterCoastal Indonesia can assist you in finding the best cover for a ship repairer who needs to cover loss or damage to vessels at all times whilst in their care, custody and control; including whilst being worked upon, being shifted in tow, or otherwise, or even on trial runs. The coverage not only includes damage to third party property, but also loss of life or injury to persons resulting from negligence by workers, agents or sub-contractors.",
        imgUrl: repair,
    },
    {
        title: "Ship Builder Risk",
        description: "PT InterCoastal Indonesia can help you design coverage that protects builders from risk exposures during process of construction, re-construction or modification of a vessel. In addition to providing cover for physical loss or damage to new vessels under construction, insurance cover will normally also include cover for third party liabilities, damage arising from faulty design, sea trials, war risks (whilst waterborne) and strikes coverage. Where appropriate, cover can be extended to include cargo risks for parts and machinery during storage and transportation to to the yard, towage of a hull from a sub-contractors yard and expenses incurred by the yard following postponement of a launch.",
        imgUrl: builder,
    },
    {
        title: "Legal And Defense",
        description: "PT InterCoastal Indonesia can extend the P&I cover to include legal assistance & defense which covers the legal costs, which must reasonable be made to protect the client in respect of claims or disputes, which are arising from contracts made to perform the marine adventure. Clients will be covered for the costs of external lawyers or other service providers. This coverage will not be underwritten on a 'stand alone' basis, but only in combination with Protection & Indemnity (P&I)",
        imgUrl: legal,
    },
    {
        title: "Additional Risk Coverage",
        description: "We can provide cover for any additional marine insurance required, such as: war risks, bunker, freight, detention, SOL, etc. PT InterCoastal Indonesia delivers better service, better knowledge of the client business and comprehensive solutions; most importantly, though, we are fully committed to our clients and partners. The one with the lowest, or even highest, price may not always be the best; but one thing for sure, we will not approach you with anything less than excellent quality.",
        imgUrl: additional,
    }
  ];

  const projects2 = [
    {
      title: "Contractor All Risk",
      description: "This policy offers comprehensive protection against loss and/or damage in respects of civil engineering construction works and mechanical electrical works",
      imgUrl: projImg4,
    },
    {
      title: "Energy, Oil & Gas",
      description: "This policy covers risks of the energy plant either during operation or silent risks; construction of drilling rigs, platform in operation, off-shore builder's risks, floating risks, control of well and other types related to oil & gas industry.",
      imgUrl: projImg5,
    },
    {
      title: "Travel Insurance",
      description: "This policy covers the costs and losses associated with traveling. It is useful protection for those traveling domestically or abroad. The main categories of travel insurance include trip cancellation or interruption coverage, baggage and personal effects coverage, medical expense coverage, and accidental death or flight accident coverage. ",
      imgUrl: projImg6,
    },
    {
      title: "Aviation - Hull & Liability",
      description: "This policy is a comprehensive cover for an aircraft during itâ€™s operation, to any loss or damage to the aircraft Hull & Machinery, Hull War and excess liabilities, deductible, loss of use, spares including transit, cargo passengerâ€™s legal liability, Loss of License and Personal Accident to aircraft crew.",
      imgUrl: aviation,
    },
    {
      title: "Aviation - Airport & Civil Authorities",
      description: "This policy cover the Airport, ATC, Ground/Ramp Handlers, Revelers, Liability, Spares including transit, industrial all risk including equipment.",
      imgUrl: airport,
    },
    {
      title: "Computer All Risk",
      description: "This policy covers electronics or computers, against loss or damage from any unforeseen losses, for any kind equipments of low and medium voltage electronics equipment including computers and itâ€™s peripherals.",
      imgUrl: computer,
    },
    {
        title: "Erection All Risk",
        description: "This policy covers erection of a Plant and/or Machinery, including Workmen Compensation and Third Party Liabilities",
        imgUrl: erection,
    },
    {
        title: "MV & Personal Accident Insurance",
        description: "This policy protects the MV owner, against loss or damage to the Vehicle being Insured, and this insurance is also cover Third Party Liability, Passengers Liability and Personal Accident for the Driver. Under this insurance policy, is provided to the Insured or the beneficiary in case of an accident, which may cause injury, hospitalized, death or permanent disablement and medical expenses.",
        imgUrl: mv_personal,
    },
    {
        title: "Health Insurance",
        description: "This policy covers hospital and surgical expenses of any person who buy this insurance. This policy mostly is taken by companies, which care and protect their executives and employee against the expensive hospital and surgical expenses.",
        imgUrl: health,
    },
  ];

  const projects3 = [
    {
      title: "Bank Guarantee & Surety Bond",
      description: "A surety bond is a legally binding contract entered into by three parties—the principal, the obligee, and the surety. The obligee, usually a government entity, requires the principal, typically a business owner or contractor, to obtain a surety bond as a guarantee against future work performance. 	Provide guarantees in the form of counter guarantees for bank guarantee facilities issued by the Bank to the Principal if the Principal experiences a default in the performance of his work.",
      imgUrl: projImg7,
    },
    {
      title: "Money Insurance",
      description: "This policy protects your business money (which will include not only cash and cheques, but also items such as lottery tickets, gift cards, postal orders and stamps) by providing cover for loss or damage to it whilst on your premises or in transit.",
      imgUrl: projImg8,
    },
    {
      title: "Corporate Liability",
      description: "This policy offers protection against such events. It provides financial security – covering any funds needed to defend yourself and your company in a court of law and ensuring that, in such difficult times, your business stays afloat",
      imgUrl: projImg9,
    }
  ];

  return (
    <section className="project" id="services">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>List of our Services</h2>
                <p> We provide services for Marine Insurance, General Insurance and Financial Lines Insurance.
                </p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Marine Insurance</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">General Insurance</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Financial Lines</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ServiceCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Row>
                        {
                          projects2.map((project2, index) => {
                            return (
                              <ServiceCard
                                key={index}
                                {...project2}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <Row>
                        {
                          projects3.map((project3, index) => {
                            return (
                              <ServiceCard
                                key={index}
                                {...project3}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
          <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn d-flex justify-content-center": "d-flex justify-content-center"}>
                <span className="navbar-text">   
                  <a href="https://wa.me/6282124940209" target="_blank" rel="noopener noreferrer"><button className="vvd" style={{backgroundColor: "#333893"}}><span>Further Inquiry ?</span></button></a>    
                </span>  
              </div> } 
          </TrackVisibility>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="colorsharp"></img>
    </section>
  )
}
