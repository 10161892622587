import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Visimisi = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="visimisi">
        <Container>
            <Row>
                <Col xs={12} md={12} xl={12}>
                    <div className="visimisi-bx wow zoomIn">
                        <h2 className="pb-3">More About Us...</h2>
                        <Row>
                            <Col xs={12} md={6} xl={4}>
                                <FontAwesomeIcon className="pb-2" icon="eye" size="3x"/>
                                <h3>Vision</h3>
                            </Col>
                            <Col xs={12} md={6} xl={8}>
                                <p style={{textAlign:'justify'}}>To become on of the leading insurance brokers especially in P&I and marine insurance in Indonesia by the year 2020.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6} xl={4}>
                                <FontAwesomeIcon className="pb-2" icon="circle-dot" size="3x"/>
                                <h3>Mission</h3>
                            </Col>
                            <Col xs={12} md={6} xl={8}>
                            <ul class="list-unstyled text-start">
                                <li>
                                    <ul>
                                    <li>Our Mission To help the growth of the insurance industry in general.</li>
                                    <li>To provide service for Indonesian companies in facing the era of free trade and rise of the global market in the third millennium, especially in matters of insurance.</li>
                                    <li>To provide the best possible service in marine insurance and risk Management above and beyond international standard.</li>
                                    <li>To provide solutions in P&I and marine insurance needs of clients, with consideration to budgets and requirements.</li>
                                    <li>To provide continuity service and defend the interest of clients through professional management and staff.</li>
                                    </ul>
                                </li>
                            </ul>
                            </Col>
                        </Row>
                        <p></p>
                        <p></p>
                    </div>
                    <div className="skill-bx-core wow zoomIn">
                      <h2 className="mb-3">Why Choose Us ?</h2>   
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <FontAwesomeIcon className="py-3" icon="wifi-strong" size="5x" fade/>
                                <h4>Communication</h4>
                                <p>The insurance business rely on clear and swift communication between all parties involved.</p>
                            </div>
                            <div className="item">
                                <FontAwesomeIcon className="py-3" icon="lightbulb" size="5x" fade/>
                                <h4>Solution</h4>
                                <p>Solutions We provide affordable and comprehensive solutions, tailor-made to the client's requirements</p>
                            </div>
                            <div className="item">
                                <FontAwesomeIcon className="py-3" icon="hands-helping" size="5x" fade/>
                                <h4>Commitment</h4>
                                <p>Commitment We are a fully registered and licensed broker that are fully committe</p>
                            </div>
                           
                        </Carousel>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
  )
}
