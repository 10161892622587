import { Container, Row, Col } from "react-bootstrap";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const BannerService = () => {
  return (
    <section className="banner3" id="home">
      <Container>
        <Row className="aligh-items-center">
        <h2 className="mb-4">Our Services Provider</h2>
        <p className="fs-4">Local insurance companies that we have worked with so far, among others, are:</p>
          <Col xs={12} md={4} xl={4}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
              <ul class="list-unstyled text-start fs-5 bg-dark bg-opacity-50">
                    <li>
                        <ul>
                            <li>PT. Asuransi Jasa Indonesia (Persero)</li>
                            <li>PT. Asuransi Asoka Mas, Tbk</li>
                            <li>PT. Asuransi QBE Pool Indonesia</li>
                            <li>PT. Tugu Pratama Indonesia</li>
                            <li>PT. Asuransi Adira Dinamika</li>
                            <li>PT. Asuransi Jasa Indonesia Takaful</li>
                            <li>PT. Asuransi Kredit Indonesia (ASKRINDO)</li>
                            <li>PT. Asuransi Sinar Mas</li>
                            <li>PT. Asuransi Wahana Tata</li>
                            <li>PT. Asuransi Binagriya Upakara</li>
                            <li>PT. Asuransi Jaya Proteksi Takaful</li>
                            <li>PT. Asuransi Intra Asia</li>
                            <li>PT. Malaca Trust Wuwungan Insurance</li>
                            <li>PT. Asuransi Beringin Sejahtera Artamakmur</li>
                        </ul>
                    </li>
                </ul>    
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={4} xl={4}>
          <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <ul class="list-unstyled text-start fs-5 bg-dark bg-opacity-50">
                    <li>
                        <ul>
                            <li>PT. Asuransi Central Asia</li>
                            <li>PT. Asuransi Purna Artanugraha (ASPAN)</li>
                            <li>PT. Asuransi Indrapura</li>
                            <li>PT. Raya Insurance</li>
                            <li>PT. AIG Insurance Indonesia</li>
                            <li>PT. Asuransi Tripakarta</li>
                            <li>PT. Asuransi Kresna Pratama</li>
                            <li>PT. Asuransi Bintang</li>
                            <li>PT. Asuransi Astra</li>
                            <li>PT. Asuransi Ekspor Indonesia (ASEI)</li>
                            <li>PT. Asuransi Reliance Indonesia</li>
                            <li>PT. MNC Asuransi Indonesia</li>
                            <li>PT. Asuransi Mega Pratama</li>
                            <li>PT. Asuransi Rama Satria Wibawa</li>    
                        </ul>
                    </li>
                </ul>
              </div>}
            </TrackVisibility>  
          </Col>
          <Col xs={12} md={4} xl={4}>
          <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <ul class="list-unstyled text-start fs-5 bg-dark bg-opacity-50">
                    <li>
                        <ul>
                            <li>PT. Asuransi Bangun Askrida</li>
                            <li>PT. Ace Jaya Proteksi</li>
                            <li>PT. Asuransi Allians Utama</li>
                            <li>PT. Asuransi Bosowa</li>
                            <li>PT. Lippo General Insurance</li>
                            <li>PT. Tokio Marine</li>
                            <li>PT. Asuransi Jasa Tania, Tbk</li>
                            <li>PT. Asuransi Himalaya Pelindung</li>
                            <li>PT. Panin Insurance, Tbk</li>
                            <li>PT. KSK Insurance Indonesia</li>
                            <li>PT. China Taiping Insurance Indonesia</li>
                            <li>and other, state-owned or private-owned, reputable insurance companies</li>   
                        </ul>
                    </li>
                </ul>
              </div>}
            </TrackVisibility>  
          </Col>
          <div className="animate__animated animate__fadeIn">
            <span className="navbar-text">    
              <a href="https://wa.me/6282124940209" target="_blank" rel="noopener noreferrer"><button className="vvd"><span>Chat Us!</span> <ArrowRightCircle size={25} /></button></a>
            </span>  
          </div>
        </Row>
      </Container>
    </section>
  )
}
