import React from 'react'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

export const Timeline = () => {
  return (
    <section className="timeline" id="timeline">
        <div className="container">
            <div className="row">
                <div className="col-12">
                <h2 className="text-center mb-4">Our Company Journey</h2>
                <VerticalTimeline lineColor="#05a4f2">
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#05a4f2', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid #05a4f2' }}
                        date="Early 2001"
                        iconStyle={{ background: '#05a4f2', color: '#fff' }}
                        icon={<faWifiStrong />}
                    >
                        <h4 className="vertical-timeline-element-title">WE START OUR BUSINESS</h4>
                        <h5 className="vertical-timeline-element-subtitle">New Beginning</h5>
                        <p style={{textAlign:'justify'}}>
                        PT. InterCoastal Indonesia was established in February 2001 by deed of public notary Yonsah Minanda SH, MH 
                        under articles of corporation number 27 dated February 07, 2001
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#05a4f2', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid #05a4f2' }}
                        date="Mid 2001"
                        iconStyle={{ background: '#333893', color: '#fff' }}
                        icon={<faWifiStrong />}
                    >
                        <h4 className="vertical-timeline-element-title">WE ESTABLISH RELATIONSHIP WITH RAETSMARINE</h4>
                        <h5 className="vertical-timeline-element-subtitle">Business Partnership</h5>
                        <p style={{textAlign:'justify'}}>
                        PT. InterCoastal Indonesia was appointed exclusively as marketing by RaetsMarine Insurance B.V main office, 
                        To provide fixed premium facility for vessels under 5000 GT with the limit of liabilities of USD 5,000,000
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#05a4f2', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid #05a4f2' }}
                        date="Early 2005"
                        iconStyle={{ background: '#05a4f2', color: '#fff' }}
                        icon={<faWifiStrong />}
                    >
                        <h4 className="vertical-timeline-element-title">WE ARE CHANGING</h4>
                        <h5 className="vertical-timeline-element-subtitle">Product Expansion</h5>
                        <p style={{textAlign:'justify'}}>
                        PT. InterCoastal Indonesia changed to become insurance broker and risk consultant company
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#05a4f2', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid #05a4f2' }}
                        date="Mid 2005"
                        iconStyle={{ background: '#333893', color: '#fff' }}
                        icon={<faWifiStrong />}
                    >
                        <h4 className="vertical-timeline-element-title">WE PARTICIPATE IN ORGANIZATION</h4>
                        <h5 className="vertical-timeline-element-subtitle">Association Membership</h5>
                        <p style={{textAlign:'justify'}}>
                        PT. InterCoastal Indonesia has become a membe of APPARINDO (Insurance and Reinsurance Broker Association of Indonesia) 
                        under registry number 116-2005/APPARINDO/2013
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#05a4f2', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid #05a4f2' }}
                        date="Mid 2012"
                        iconStyle={{ background: '#05a4f2', color: '#fff' }}
                        icon={<faWifiStrong />}
                    >
                        <h4 className="vertical-timeline-element-title">WE EXPAND OUR NETWORK</h4>
                        <h5 className="vertical-timeline-element-subtitle">More Partnership</h5>
                        <p style={{textAlign:'justify'}}>
                        RaetsMarine Insurance B.V has been bought off by MS Amlin Insurance Group which is located in Netherlands. Due to  
                        heavy alteration within management by MS Amlin Insurance Group towards RaetsMarine Insurance B.V, PT. InterCoastal 
                        Indonesia has decided to extend their partnership to other international fixed premium facilities & group
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#05a4f2', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid #05a4f2' }}
                        date="Early 2016"
                        iconStyle={{ background: '#333893', color: '#fff' }}
                        icon={<faWifiStrong />}
                    >
                        <h4 className="vertical-timeline-element-title">WE EXPAND OUR COMPANY</h4>
                        <h5 className="vertical-timeline-element-subtitle">Business Merger</h5>
                        <p style={{textAlign:'justify'}}>
                        PT. Cipta Uni Jasa Insurance Broker who has thirty (30) years experience in the Insurance Industry, especially Maritime Insurance, 
                        merged with PT. InterCoastal Indonesia Insurance Broker. Most of our clients are ranging from private shipping company until government 
                        sector including non-marine insurance.
                        </p>
                    </VerticalTimelineElement>
                </VerticalTimeline>    
                </div>
            </div>
        </div>
    </section>
  )
}
