import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Footer } from "./components/Footer";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faWifiStrong, faLightbulb, faHandsHelping, faEye, faCircleDot} from "@fortawesome/free-solid-svg-icons";
import AnimatedRoutes from './components/AnimatedRoutes';

library.add( faWifiStrong, faLightbulb, faHandsHelping, faEye, faCircleDot);

function App() {
  return (
    <div className="App">
      <NavBar />
      <AnimatedRoutes /> 
      <Footer />
    </div>
  );
}

export default App;
